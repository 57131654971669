<template>
<div>
    <div class="speech">
        <div class="example">
            <div class="item">
                <div class=example-title>视频示例</div>
                <div class="item-list">
                    <div class="example-box" :class="{ isActive: video.name == num }" v-for="(video, idx) in videoList" :key="idx" @click="changeVideo(video, idx)">
                        <span>{{ video.name }}</span>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class=example-title>语音示例</div>
                <div class="item-list">
                    <div class="example-box" :class="{ isActive: video.name == num }" v-for="(video, idx) in audioList" :key="idx" @click="changeVideo(video, idx)">
                        <span>{{ video.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 识别内容 -->
        <div class="ocr-content" v-loading="loading" element-loading-text="识别中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="ocr-box-title">识别内容</div>
            <div class="images-container">
                <video ref="video" id="video" class="speech-video" autoplay controls="controls" x5-video-player-type='h5' x5-video-player-fullscreen="true" playsinline webkit-playsinline>
                    <source :src="curentvideo" type="video/mp4" />
                </video>
            </div>
            <div class="upload-tool">
                <el-upload class="upload-demo" :http-request="customUpload" action="" accept="video/mp3,video/mp4,audio/mp3" :show-file-list="false">
                    <el-button type="primary">本地上传</el-button>
                </el-upload>
                <el-input class="input" v-model="inputVideo" placeholder="请输入或者粘贴音视频链接URL" @input="searchVideo"></el-input>
            </div>
        </div>
        <!-- 识别结果 -->
        <div class="ocr-result">
            <div class="ocr-box-title">处理结果</div>
            <div class="ocr-result-box">
                <div v-if="Object.keys(speechResult).length">
                    <p class="result"><strong>拼音：</strong>{{ speechResult.pinyin }}</p>
                    <p class="result"><strong>文字：</strong>{{ speechResult.text }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import config from '@/lib/config.js';
export default {
    data() {
        this.searchVideo = this.debounce(() => {
            this.search();
        }, 1000);
        return {
            num: '示例视频1',
            loading: false,
            inputVideo: "",
            speechResult: {},
            fname: "filename.mp3",
            curentvideo: "videos/sample1.mp4", ///videos/1.mp4
            imageType: [],
            videoList: [{
                    name: "示例视频1",
                    src: "/videos/sample1.mp4",
                },
                {
                    name: "示例视频2",
                    src: "/videos/sample2.mp4",
                },

            ],
            audioList: [{
                    name: "示例音频1",
                    src: "/videos/sample3.wav",
                },
                {
                    name: "示例音频2",
                    src: "./videos/sample4.wav",
                },
            ]
        };
    },
    mounted() {
        this.onSubmit();
    },
    unmounted() {
        if (process.env.NODE_ENV == 'production') {
            axios.get('/server.api.json').then(res => {
                axios.defaults.baseURL = res.data.baseUrl;
            })
        } else {
            axios.defaults.baseURL = '/api'
        }
    },
    methods: {
        /* 选择视频 */
        changeVideo(video, idx) {
            this.num = video.name;
            this.inputVideo = '';
            this.reloadVideo(video.src);
        },
        /* 重新渲染视频 */
        async reloadVideo(src) {
            this.curentvideo = src;
            this.$refs["video"].load();
            this.$refs["video"].oncanplaythrough = await this.onSubmit();
        },
        search() {
            this.num = null;
            if (this.inputVideo !== '') return this.reloadVideo(this.inputVideo);
        },
        getFileData(src) {
            axios.defaults.baseURL = '/';
            axios({
                    url: src,
                    method: "get",
                    responseType: "blob"
                })
                .then((res) => {
                    let blob = new Blob([res.data]);
                    let filename = src.indexOf('/') > -1 ? src.substring(src.lastIndexOf('/') + 1) : filename;
                    let file = new File([blob], this.fname ? this.fname : filename);
                    this.uploadflie(file);
                }),
                (error) => {
                    this.$message({
                        type: "warning",
                        message: "系统错误，请联系管理员!",
                    });
                };
        },
        // 自定义本地上传
        async customUpload(e) {
            let file = e.file;
            await this.fileToBlob(file, fn => {
                this.$refs.video.src = fn;
                this.num = null;
                this.inputVideo = '';
                return this.uploadflie(file);
            });

        },
        // 文件上传
        async uploadflie(file) {
            this.loading = true;
            this.speechResult = "";
            let formData = new FormData();
            formData.append("audio", file);
            const res = await this.$upLoadFile('http://ai.chinabdc.com.cn/asr', formData).catch(err => {
                this.loading = false;
            })
            if (res.success) {
                this.loading = false;
                this.speechResult = res.data[0];
                return
            }
            this.loading = false;
            this.$message.error('识别失败');
        },
        onSubmit() {
            this.getFileData(this.curentvideo);
        },
    },
}
</script>

<style lang="scss" scoped>
.speech {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .example {
        width: 130px;
        height: 390px;
        display: flex;
        overflow-y: auto;
        padding: 12px 10px;
        flex-direction: column;
        box-sizing: border-box;
        background-color: #f2f2f2;

        .example-title {
            padding: 6px 0;
            font-size: 16px;
            color: #f1a829;
            font-weight: bold;
        }

        .example-box {
            width: 100%;
            padding: 6px;
            display: flex;
            font-size: 12px;
            margin-bottom: 6px;
            border-radius: 4px;
            background: #fff;
            justify-items: center;
            box-sizing: border-box;
            justify-content: center;
            transition: all linear .3s;
            border: 2px solid rgba(0, 0, 0, 0);
            cursor: pointer;

            span {
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
                word-break: break-all;
                text-overflow: ellipsis;
            }
        }

        .isActive {
            border: 2px solid #409eff;
        }
    }

    .ocr-content,
    .ocr-result {
        flex: 1;
        height: 390px;
        padding: 12px;
        display: flex;
        overflow: hidden;
        border-radius: 4px;
        margin-left: 12px;
        box-sizing: border-box;
        flex-direction: column;
        background-color: #f2f2f2;

        .ocr-box-title {
            width: 108px;
            height: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
            text-align: center;
            background-color: #fff;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .images-container {
            width: 100%;
            padding: 34px;
            height: 300px;
            display: flex;
            overflow: hidden;
            justify-items: center;
            box-sizing: border-box;
            justify-content: center;
            background-color: #fff;

            #video {
                width: 100%;
                height: 100%;
            }
        }

        .upload-tool {
            display: flex;
            margin-top: 12px;
            align-items: center;
            justify-content: space-between;

            .label {
                width: 120px;
                display: flex;
                color: #fff;
                padding: 12px;
                font-size: 12px;
                cursor: pointer;
                border-radius: 4px;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                background-color: #409eff;
            }

            .input {
                margin-left: 12px;
                box-sizing: border-box;
            }
        }

        .ocr-result-box {
            flex: 1;
            width: 100%;
            padding: 12px;
            overflow: auto;
            font-size: 14px;
            box-sizing: border-box;
            background-color: #fff;

            .result {
                margin: 0;
                padding: 12px 0;
            }
        }
    }
}

@media (max-width:576px) {
    .cell {
        padding: 12px 0;
    }

    .speech {
        width: 100%;
        display: flex;
        padding: 0 14px;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;

        .example {
            width: 100%;
            height: auto;
            padding: 6px 12px;
            flex-direction: row;
            box-sizing: border-box;
            justify-content: space-between;

            .example-title {
                font-size: 14px;
            }

            .item {
                flex: 1;
                padding: 0 12px;
                box-sizing: border-box;

                .item-list {
                    display: flex;
                }
            }

            .item:first-child{
                border-right: 1px dashed #409eff;
            }

            .example-box {
                flex: 1;
                padding: 6px;
                font-size: 12px;
                margin-bottom: 0;
                margin-right: 6px;
            }

            .example-box:last-child {
                margin-right: 0;
            }
        }

        .ocr-content,
        .ocr-result {
            height: auto;
            width: 100%;
            margin-left: 0;
            margin-top: 12px;

            .ocr-box-title {
                font-size: 16px;
                font-weight: 600;
            }

            .images-container {
                padding: 12px;

                #video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .upload-tool {
                display: flex;
                margin-top: 12px;
                align-items: center;
                justify-content: space-between;

                .label {
                    width: 120px;
                    display: flex;
                    color: #fff;
                    padding: 12px;
                    font-size: 12px;
                    cursor: pointer;
                    border-radius: 4px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: center;
                    background-color: #409eff;
                }
            }

            .ocr-result-box {
                flex: 1;
                width: 100%;
                padding: 12px;
                overflow: auto;
                font-size: 14px;
                box-sizing: border-box;
                background-color: #fff;

                .result {
                    margin: 0;
                    padding: 12px 0;
                }
            }
        }
    }
}
</style>
