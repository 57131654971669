<template>
<div class="tabs">
    <el-tabs v-model="activeName" stretch @tab-click="handleClick">
        <el-tab-pane label="图片压缩" name="0">
            <proModule />
            <div class="desc">采用深度学习卷积网络为编码内核，解压文件支持后缀名为.hfc压缩文件</div>
            <div class='upload-demo-box' v-loading='loading' element-loading-text="正在上传" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                <el-upload v-if='!uploadFlag' class="upload-demo" :http-request="uploadFile" action="" :limit="1" :show-file-list="false">
                    <div class="upload">
                        <el-button type="primary">上传压缩包</el-button>
                    </div>
                </el-upload>
                <div class="upload-demo-again" v-if='uploadFlag'>
                    <div class="filename">{{filename}}</div>
                    <div class="btn-group">
                        <div class="upload">
                            <el-button type="primary" @click="download()">下载到本地</el-button>
                        </div>
                        <el-upload v-if='uploadFlag' class="upload-demo" :http-request="uploadFile" action="" :limit="1" :show-file-list="false">
                            <div class="upload">
                                <el-button type="default" :style="{background:'#fff',border:'solid 1px #f0ab49',color:'#f0ab49'}">重传文件</el-button>
                            </div>
                        </el-upload>
                    </div>
                </div>

            </div>
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
import proModule from '@/components/proModule/index';
export default {
    components: {
        proModule
    },
    data() {
        return {
            compress: {},
            filename: '',
            activeName: '0',
            loading: false,
            Decompress_Result_Path: '',
            uploadFlag: false, //上传压缩包状态标识
        }
    },
    methods: {
        // 图片上传
        async uploadFile(files) {
            this.loading = true;
            let file = files.file || file;
            let formdata = new FormData();
            formdata.append('file', file);
            const res = await this.$upLoadFile('/ai-img/detect', formdata).catch(err => {
                this.loading = false;
            });
            if (res.success && res.data) {
                this.searchVal = '';
                this.loading = false;
                this.compress = res.data;
                this.filename = files.file.name;
                this.Decompress_Result_Path = res.data.Decompress_Result_Path
                this.$message.success('处理成功');
                this.uploadFlag = true
            } else {
                this.loading = false;
                this.uploadFlag = false;
                this.$message.error(res.message);
            }
        },
        download() {
            window.open(this.baseUrl + this.Decompress_Result_Path)
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs {
    width: 100%;

    .el-tabs {
        .el-tabs__nav-wrap {
            height: 78px;

            .el-tabs__nav-scroll {
                height: 100%;

                .el-tabs__nav {
                    height: 100%;

                    .el-tabs__item {
                        height: 78px;
                        line-height: 78px;
                    }
                }
            }
        }
    }

    .desc {
        padding: 12px 0;
        font-size: 14px;
        margin-top: 24px;
        text-align: center;
    }

    .upload {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filename {
        font-size: 14px;
        cursor: default;
        pointer-events: none;
    }

    .placeHolder {
        margin: 24px 0;
        font-size: 14px;
        text-align: center;
    }

    .upload-demo-box {
        padding: 16px;
        background: #f2f2f2;

        .upload-demo-again {
            display: flex;
            padding: 16px;
            background: #fff;
            align-items: center;
            flex-direction: column;

            .btn-group {
                display: flex;
            }
        }

        .upload-demo {
            display: flex;
            justify-content: center;
            background: #fff;
            padding: 16px;
        }
    }
}

@media (max-width:576px) {
    .tabs {
        width: 100%;

        .desc {
            padding: 12px;
            margin-top: 0;
            font-size: 12px;
            box-sizing: border-box;
        }

        .upload {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .filename {
            font-size: 12px;
            cursor: default;
            pointer-events: none;
        }

        .placeHolder {
            margin: 24px 0;
            font-size: 14px;
            text-align: center;
        }

        .upload-demo-box {
            padding: 12px;
            box-sizing: border-box;

            .upload-demo-again {
                padding: 12px 0;

                .btn-group {
                    padding: 12px 0;

                    .upload-demo {
                        padding: 0 12px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
</style>
