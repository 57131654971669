<template>
<div class="sign">
    <!-- 描述信息 -->
    <p class="desc">{{ desc }}</p>
    <!-- 内容区 -->
    <div class="content">
        <div class="example">
            <div class="cell" :class="{'isActive' :current == idx}" v-for="(item,idx) in examplelist" :key="item.id" @click="changeImg(item,idx)">
                <img class="img" :src="item" alt="示例图片" />
            </div>
        </div>
        <!-- 识别内容 -->
        <div class="ocr-content" v-loading="loading" element-loading-text="识别中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="ocr-box-title">识别内容</div>
            <div class="images-container">
                <el-image style="width:95%;height:95%;border-radius:4px;" fit="contain" :src="identifyImg" :preview-src-list="[identifyImg]">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </div>
            <div class="upload-tool">
                <el-upload class="upload-demo" :http-request="fileChange" action="" accept="image/*" :show-file-list="false">
                    <el-button type="primary">本地上传</el-button>
                </el-upload>
                <el-input class="input" v-model="inputImg" placeholder="请输入或者粘贴图像链接URL" @change="searchImg"></el-input>
            </div>
        </div>
        <!-- 识别结果 -->
        <div class="ocr-result">
            <div class="ocr-box-title">处理结果</div>
            <div class="ocr-result-box">
                <el-image fit="contain" :src="resultImg" :preview-src-list="[resultImg]">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import config from '@/lib/config.js';
export default {
    data() {
        return {
            num: 0,
            type: '',
            ocrList: [],
            current: 0,
            inputImg: '',
            fileList: [],
            loading: false,
            temImg: '',
            resultImg: '',
            desc: config.sign,
            txtQrcodeList: config.txtQrcodeList,
            identifyImg: "/images/example/1_2.jpg",
            examplelist: ['/images/example/1_2.jpg', '/images/example/3_2.jpeg'],
        }
    },
    mounted() {
        this.imgToBase64(this.identifyImg, async base => {
            let file = this.base64toFile(base, '图片');
            return this.onSubmit(file);
        })
    },
    methods: {
        async onSubmit(file) {
            this.loading = true;
            let formdata = new FormData();
            formdata.append('image', file);
            const res = await this.$upLoadFile('/signatureDetection', formdata);
            if (res.success) {
                this.loading = false;
                this.resultImg = res.image;
                return
            }
            this.loading = false;
            this.$message.error(res.message)
        },
        changeImg(img, idx) {
            this.current = idx;
            this.identifyImg = img;
            this.imgToBase64(img, async base => {
                let file = this.base64toFile(base, '图片');
                return this.onSubmit(file);
            })
        },
        async searchImg() {
            if (this.inputImg == '') return;
            const res = await this.$httpPost('/ai-web/home/dowImg', {
                url: this.inputImg,
            })
            if (res.success && res.data) {

                this.identifyImg = res.data;
                let file = this.base64toFile(res.data, '图片');
                this.onSubmit(file);
                return;
            }
            this.$message('图片下载失败');
        },
        // 本地文件上传
        fileChange(e) {
            this.inputImg = '';
            const fileData = e.file;
            const reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = (s) => {
                this.identifyImg = s.target.result;
                this.onSubmit(fileData);
            };
        },
    }
}
</script>

<style lang="scss" scoped>
.sign {
    width: 100%;

    .desc {
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        margin-top: 24px;
        line-height: 24px;
        color: #333333;
        text-align: center;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .example {
            width: 150px;
            height: 390px;
            display: flex;
            overflow-y: auto;
            padding: 12px 10px;
            border-radius: 4px;
            flex-direction: column;
            box-sizing: border-box;
            background-color: #f2f2f2;

            .cell {
                width: 100%;
                padding: 6px;
                cursor: pointer;
                margin-bottom: 6px;
                border-radius: 4px;
                background: #fff;
                box-sizing: border-box;
                transition: all linear .3s;
                border: 2px solid rgba(0, 0, 0, 0);

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }

            .isActive {
                border: 2px solid #409eff;
            }
        }

        .ocr-content,
        .ocr-result {
            flex: 1;
            height: 390px;
            padding: 12px;
            display: flex;
            margin-left: 12px;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            flex-direction: column;
            background-color: #f2f2f2;
            justify-content: space-between;

            .ocr-box-title {
                width: 108px;
                height: 30px;
                display: flex;
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .images-container {
                flex: 1;
                width: 100%;
                display: flex;
                overflow: hidden;
                position: relative;
                padding: 12px 34px;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                background-color: #fff;
            }

            .ocr-result-box {
                flex: 1;
                width: 100%;
                padding: 12px;
                overflow: hidden;
                box-sizing: border-box;
                background-color: #fff;

                .el-image {
                    width: 100%;
                    height: 100%;
                    display: block;
                    overflow: hidden;
                    border-radius: 4px;

                    .image-slot {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            font-size: 42px;
                        }
                    }
                }
            }

            .upload-tool {
                display: flex;
                margin-top: 12px;
                align-items: center;
                justify-content: space-between;

                .label {
                    width: 120px;
                    display: flex;
                    color: #fff;
                    padding: 12px;
                    font-size: 12px;
                    cursor: pointer;
                    border-radius: 4px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: center;
                    background-color: #409eff;
                }

                .input {
                    margin-left: 12px;
                    box-sizing: border-box;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .sign {
        width: 100%;

        .desc {
            font-size: 12px;
            padding: 6px 14px;
            box-sizing: border-box;
        }

        .content {
            display: flex;
            padding: 0 14px;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;

            .example {
                width: 100%;
                height: auto;
                padding: 12px;
                flex-direction: row;
                box-sizing: border-box;

                .cell {
                    flex: 1;
                    padding: 6px;
                    height: 120px;
                    cursor: pointer;
                    margin-right: 6px;
                    border-radius: 4px;
                    background: #fff;
                    box-sizing: border-box;
                    transition: all linear .3s;
                    border: 2px solid rgba(0, 0, 0, 0);

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }

                .cell:last-child {
                    margin-right: 0;
                }

                .isActive {
                    border: 2px solid #409eff;
                }
            }

            .ocr-content,
            .ocr-result {
                width: 100%;
                margin-left: 0;
                margin-top: 12px;

                .ocr-box-title {
                    font-size: 16px;
                    font-weight: 600;
                }

                .images-container {
                    width: 100%;
                    display: flex;
                    padding: 12px;
                    overflow: hidden;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: center;
                    background-color: #fff;
                }

                .ocr-result-box {
                    flex: 1;
                    width: 100%;
                    padding: 12px;
                    box-sizing: border-box;
                    background-color: #fff;

                    .el-image {

                        .image-slot {
                            height: 80px;
                        }
                    }
                }

                .upload-tool {
                    display: flex;
                    margin-top: 12px;
                    align-items: center;
                    justify-content: space-between;

                    .label {
                        width: 120px;
                        display: flex;
                        color: #fff;
                        padding: 12px;
                        font-size: 12px;
                        cursor: pointer;
                        border-radius: 4px;
                        align-items: center;
                        box-sizing: border-box;
                        justify-content: center;
                        background-color: #409eff;
                    }

                    .input {
                        padding: 0;
                        margin-left: 12px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
</style>
