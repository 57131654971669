<template>
<div class="ocr">
    <div class="desc">可自定义或从活体，眨眼、张嘴、摇头、头向左和头向右随机定检测动作。</div>
    <div class="content">
        <div class="list">
            <div class="cell" v-for="(item,idx) in list" :key="idx">
                <span class="title">{{item.title}}</span>
                <video ref="video" id="video" class="ocr-video" controls="controls" x5-video-player-type='h5' x5-video-player-fullscreen="true" playsinline webkit-playsinline>
                    <source :src="item.src" type="video/mp4" />
                </video>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                    id: 0,
                    title: '眨眼演示',
                    src: '/videos/ocr1.mp4'
                },
                {
                    id: 1,
                    title: '右转头、左转头、摇头演示',
                    src: '/videos/ocr2.mp4'
                },
                {
                    id: 2,
                    title: '张嘴演示',
                    src: '/videos/ocr3.mp4'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.ocr {
    width: 100%;

    .desc {
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        text-align: center;
    }

    .content {
        width: 100%;
        padding: 12px 0;
        box-sizing: border-box;

        .list {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cell {
                flex: 1;
                display: flex;
                margin-right: 16px;
                border-radius: 6px;
                align-items: center;
                flex-direction: column;
                box-sizing: border-box;
                border: 2px solid #409eff;

                .title {
                    margin: 12px 0;
                    font-size: 18px;
                    font-weight: bold;
                }

                .ocr-video {
                    width: 100%;
                    height: 200px;
                }
            }

            .cell:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width:576px) {

    .ocr {
        .desc {
            font-size: 12px;
            padding: 6px 14px;
            box-sizing: border-box;
        }

        .content {
            padding: 12px 32px;

            .list {
                flex-direction: column;

                .cell {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 12px;

                    .title {
                        margin: 12px 0;
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .ocr-video {
                        width: 100%;
                        height: 150px;
                    }
                }

                .cell:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
