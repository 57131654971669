<template>
<div class="imageType hideToShow">
    <p class="desc">{{ desc }}</p>
    <div class="content">
        <div class="example">
            <div class="cell" :class="{'isActive' :idx == num}" v-for="(img,idx) in examplelist" :key="idx" @click="changeImg(img,idx)">
                <img class="img" :src="img" alt="示例图片" />
            </div>
        </div>
        <!-- 识别内容 -->
        <div class="ocr-content" v-loading="loading" element-loading-text="识别中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="ocr-box-title">识别内容</div>
            <div class="images-container">
                <el-image style="width:95%;height:95%;border-radius:4px;" fit="contain" :src="identifyImg" :preview-src-list="[identifyImg]">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </div>
            <div class="upload-tool">
                <label class="label" for="file">
                    本地上传
                    <input type="file" id="file" accept="image/*" @change="fileChange" style="display: none" />
                </label>
                <el-input class="input" v-model="inputImg" placeholder="请输入或者粘贴图像链接URL" @input="searchImg"></el-input>
            </div>
        </div>
        <!-- 识别结果 -->
        <div class="ocr-result">
            <div class="ocr-box-title">处理结果</div>
            <div class="ocr-result-box">
                <!-- 图像分类类型 -->
                <el-table v-if="imageType.length" max-height="300" :data="imageType" border style="width: 100%">
                    <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                    <el-table-column prop="type" label="证件类型" align="center"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import config from '@/lib/config';
export default {
    data() {
        this.searchImg = this.debounce(() => {
            this.search();
        }, 1000);
        return {
            num: 0,
            timer: null,
            inputImg: "",
            ocrList: [],
            fileList: [],
            imageType: [],
            loading: false,
            desc: config.imgClassifyDesc,
            identifyImg: "/images/idcard/5.png",
            examplelist: ['/images/idcard/5.png', '/images/idcard/4.jpg', '/images/populace/3.jpg', '/images/residence/6.jpg'],
        }
    },
    mounted() {
        this.getImageBase64(this.identifyImg, async (base64Img) => {
            this.identifyImg = base64Img;
            await this.onSubmit()
        })
    },
    methods: {
        /* 示例图片切换 */
        changeImg(img, idx) {
            this.num = idx;
            this.inputImg = '';
            this.getImageBase64(img, async (base64Img) => {
                //转化后的base64
                this.identifyImg = base64Img;
                await this.onSubmit();
            })
        },
        /* 本地文件上传 */
        fileChange(e) {
            const reader = new FileReader();
            const fileData = e.target.files[0];
            //使用该对象读取file文件
            reader.readAsDataURL(fileData);
            //读取文件成功后执行的方法函数
            reader.onload = (s) => {
                this.identifyImg = s.target.result;
                this.num = null;
                this.inputImg = '';
                this.onSubmit()
            };
        },
        async search(){
            if (this.inputImg == '') return;
            const res = await this.$httpPost('/ai-web/home/dowImg', {
                url: this.inputImg,
            })
            if (res.success && res.data) {
                this.identifyImg = res.data;
                this.num = null;
                this.onSubmit()
                return;
            }
            this.$message('图片下载失败');
        },
        /*  执行识别 */
        async onSubmit() {
            this.loading = true;
            const res = await this.$httpPost('/ai-web/OrcApi/RequestOcr', {
                imgString: this.identifyImg,
            }).catch(err => {
                this.loading = false
            })
            if (res.success && res.data.data) {
                let data = res.data;
                this.imageType = [{
                    type: data.data[0].type
                }];
                this.loading = false;
                return
            }
            this.loading = false;
            this.$message.error(res.data.message);
        },
        /*  转换本地图片为base64 */
        getImageBase64(url, callback) {
            let canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d"),
                img = new Image;
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL('image/png');
                callback.call(this, dataURL);
                canvas = null;
            };
            img.src = url;
        },
    },
}
</script>

<style lang="scss" scoped>
.imageType {
    width: 100%;

    .desc {
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        text-align: center;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .example {
            width: 130px;
            height: 390px;
            display: flex;
            overflow-y: auto;
            padding: 12px 10px;
            flex-direction: column;
            box-sizing: border-box;
            background-color: #f2f2f2;

            .cell {
                width: 100%;
                padding: 6px;
                cursor: pointer;
                margin-bottom: 6px;
                border-radius: 4px;
                background: #fff;
                box-sizing: border-box;
                transition: all linear .3s;
                border: 2px solid rgba(0, 0, 0, 0);

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }

            .isActive {
                border: 2px solid #409eff;
            }
        }

        .ocr-content,
        .ocr-result {
            flex: 1;
            height: 390px;
            padding: 12px;
            display: flex;
            overflow: hidden;
            margin-left: 12px;
            border-radius: 4px;
            box-sizing: border-box;
            flex-direction: column;
            background-color: #f2f2f2;
            justify-content: space-between;
        }

        .ocr-box-title {
            width: 108px;
            height: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
            text-align: center;
            background-color: #fff;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .images-container {
            flex: 1;
            width: 100%;
            display: flex;
            overflow: hidden;
            padding: 12px 34px;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            background-color: #fff;
        }

        .images-container-img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: contain;
        }

        .upload-tool {
            display: flex;
            margin-top: 12px;
            align-items: center;
            justify-content: space-between;
        }

        .label {
            width: 120px;
            display: flex;
            color: #fff;
            padding: 12px;
            font-size: 12px;
            cursor: pointer;
            border-radius: 4px;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            background-color: #409eff;
        }

        .input {
            margin-left: 12px;
        }

        .ocr-result-box {
            flex: 1;
            width: 100%;
            padding: 12px;
            box-sizing: border-box;
            background-color: #fff;
        }
    }
}

@media (max-width:576px) {
    .imageType {
        width: 100%;

        .desc {
            margin: 0;
            padding: 12px;
            font-size: 12px;
            box-sizing: border-box;
        }

        .content {
            padding: 0 14px;
            box-sizing: border-box;
            flex-direction: column;

            .example {
                width: 100%;
                height: auto;
                display: flex;
                overflow-x: auto;
                padding: 6px 12px;
                flex-direction: row;
                justify-content: space-between;

                .cell {
                    width: 100%;
                    margin-bottom: 0;
                    margin-right: 6px;
                }

                .cell:last-child {
                    margin-right: 0;
                }

                .isActive {
                    border: 2px solid #409eff;
                }
            }

            .ocr-content,
            .ocr-result {
                width: 100%;
                height: auto;
                padding: 12px;
                margin-left: 0;
                margin-top: 12px;
            }

            .ocr-box-title {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .images-container {
                width: 100%;
                padding: 20px;
                overflow: hidden;
                box-sizing: border-box;
                background-color: #fff;
            }

            .images-container-img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
                object-fit: contain;
            }

            .upload-tool {
                display: flex;
                margin-top: 12px;
                align-items: center;
                justify-content: space-between;
            }

            .input {
                padding: 0;
                margin-left: 12px;
            }
        }
    }
}
</style>
