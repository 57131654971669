<template>
<div class="face hideToShow">
    <p class="desc">{{ desc }}</p>
    <div class="content">
        <div class="face-container" v-loading="loading" element-loading-text="识别中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <!-- 人脸1 -->
            <div class="face-content">
                <div class="ocr-box-title">人脸1</div>
                <div class="face-images-container">
                    <div class="example-images">
                        <div class="img-cell" :class="{'isActive' :faceNum1==idx}" v-for="(img,idx) in face1List" :key="idx" @click="changeImg('one',img,idx)">
                            <img class="cover" :src="img" alt="">
                        </div>
                    </div>
                    <div class="result-img">
                        <el-image :src="imgString1" fit="cover" :preview-src-list="[imgString1]"></el-image>
                    </div>
                </div>
                <div class="upload-tool">
                    <label class="label" for="face1">
                        本地上传
                        <input type="file" id="face1" accept="image/*" @change="fileChange('one', $event)" style="display: none">
                    </label>
                    <el-input class="input" v-model="faceImg1" placeholder="请输入或者粘贴图像链接URL" @input="searchImg('one',faceImg1)"></el-input>
                </div>
            </div>
            <!-- 人脸2 -->
            <div class="face-content">
                <div class="ocr-box-title">人脸2</div>
                <div class="face-images-container">
                    <div class="example-images">
                        <div class="img-cell" :class="{'isActive' :faceNum2==idx}" v-for="(img,idx) in face2List" :key="idx" @click="changeImg('two',img,idx)">
                            <img class="cover" :src="img" alt="">
                        </div>
                    </div>
                    <div class="result-img">
                        <el-image :src="imgString2" fit="cover" :preview-src-list="[imgString2]"></el-image>
                    </div>
                </div>
                <div class="upload-tool">
                    <label class="label" for="face2">
                        本地上传
                        <input type="file" id="face2" accept="image/*" @change="fileChange('two', $event)" style="display: none" />
                    </label>
                    <el-input class="input" v-model="faceImg2" placeholder="请输入或者粘贴图像链接URL" @input="searchImg('two',faceImg2)"></el-input>
                </div>
            </div>
        </div>
        <!-- 识别结果 -->
        <div class="ocr-result">
            <div class="ocr-box-title">处理结果</div>
            <div class="ocr-result-box">
                <el-table v-if="Object.keys(ocrResult).length" max-height="300" :data="[ocrResult]" border style="width: 100%">
                    <!-- <el-table-column prop="scores" label="相似度" align="center">
                        <template #scope>
                            <span>{{scope.row.scores}}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="result" label="对比结果" align="center">
                        <template #scope>
                            <span>{{scope.row.result }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="ocr-result-loading" v-else>待处理......</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import config from '@/lib/config';
export default {
    props: {
        ocrType: {
            type: String,
            default: "",
        },
    },
    data() {
        this.searchImg = this.debounce((idx, url) => {
            this.search(idx, url);
        }, 1000);
        return {
            faceNum1: 0,
            faceNum2: 1,
            faceImg1: "",
            faceImg2: "",
            ocrList: [],
            ocrResult: {},
            loading: false,
            desc: config.faceDesc,
            imgString1: "/images/face/3.jpg",
            imgString2: "/images/face/2.jpg",
            face1List: ['/images/face/3.jpg', '/images/face/1.jpg', '/images/face/6.jpg'],
            face2List: ['/images/face/5.jpg', '/images/face/2.jpg', '/images/face/4.jpg'],
        }
    },
    mounted() {
        this.getImageBase64(this.imgString1, (base64Img) => {
            this.imgString1 = base64Img;
        });
        this.getImageBase64(this.imgString2, (base64Img) => {
            this.imgString2 = base64Img;
        });
        return this.onSubmit();
    },
    methods: {
        changeImg(type, img, index) {
            type == "one" ? this.faceNum1 = index : this.faceNum2 = index;
            this.getImageBase64(img, (base64Img) => {
                type == "one" ? (this.imgString1 = base64Img) : (this.imgString2 = base64Img);
                this.onSubmit();
            })
        },
        /* 本地文件上传 */
        fileChange(type, file) {
            const reader = new FileReader();
            const fileData = file.target.files[0];
            //使用该对象读取file文件
            reader.readAsDataURL(fileData);
            //读取文件成功后执行的方法函数
            reader.onload = (s) => {
                type == "one" ? (this.imgString1 = s.target.result, this.faceNum1 = null) : (this.imgString2 = s.target.result, this.faceNum2 = null);
                type == "one" ? this.faceImg1 = '' : this.faceImg2 = '';
                this.onSubmit();
            };
        },
        async search(idx, url) {
            console.log(idx, url)
            if (url == '') return;
            const res = await this.$httpPost('/ai-web/home/dowImg', {
                url: url,
            })
            if (res.success && res.data) {
                idx == 'one' ? (this.imgString1 = res.data, this.faceNum1 = null) : (this.imgString2 = res.data, this.faceNum2 = null);
                this.onSubmit();
                return;
            }
            this.$message('图片下载失败');
        },
        async onSubmit() {
            this.loading = true;
            const res = await this.$httpPost('/ai-web/Face/FaceRun', {
                imgString1: this.imgString1,
                imgString2: this.imgString2,
            }).catch(err => {
                this.loading = false
            })
            if (res.success) {
                this.loading = false;
                let data = res.data;
                data.result = data.result ? '通过' : '未通过'
                data.scores = data.scores.toFixed(3) + '%';
                this.ocrResult = res.data;
                return;
            }
            this.loading = false;
            this.$message.error(res.message);
        },
        /*  转换本地图片为base64 */
        getImageBase64(url, callback) {
            let canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d"),
                img = new Image;
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL('image/png');
                callback.call(this, dataURL);
                canvas = null;
            };
            img.src = url;
        }
    },
}
</script>

<style lang="scss" scoped>
.face {
    width: 100%;

    .desc {
        padding: 12px 0;
        font-size: 14px;
        margin-top: 24px;
        text-align: center;
    }

    .content {
        display: flex;
        justify-content: space-between;

        .face-container {
            width: 70%;
            display: flex;
            margin-right: 12px;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            background-color: #f2f2f2;

            .face-content {
                flex: 1;
                padding: 12px;
                display: flex;
                overflow: hidden;
                border-radius: 4px;
                box-sizing: border-box;
                flex-direction: column;
                background-color: #f2f2f2;

                .ocr-box-title {
                    width: 108px;
                    height: 30px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 30px;
                    text-align: center;
                    background-color: #fff;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                .face-images-container {
                    height: 100%;
                    display: flex;
                    padding: 12px;
                    background-color: #ffffff;

                    .example-images {
                        width: 120px;
                        padding: 8px;
                        display: flex;
                        overflow-y: auto;
                        align-items: center;
                        box-sizing: border-box;
                        flex-direction: column;
                        background-color: #f9f9f9;

                        .img-cell {
                            width: 100%;
                            padding: 6px;
                            cursor: pointer;
                            border-radius: 4px;
                            margin-bottom: 12px;
                            box-sizing: border-box;
                            background-color: #ffffff;
                            transition: all linear .3s;
                            border: 2px solid rgba(0, 0, 0, 0);

                            img {
                                width: 100%;
                                height: 72px;
                                object-fit: cover;
                                border-radius: 4px;
                            }
                        }

                        .isActive {
                            border: 2px solid #409eff;
                        }
                    }

                    .result-img {
                        flex: 1;
                        display: flex;
                        overflow: hidden;
                        margin-left: 12px;
                        align-items: center;
                        justify-content: center;
                        background-color: #f9f9f9;

                        img {
                            width: 90%;
                            height: 90%;
                            border-radius: 4px;
                            object-fit: contain;
                        }
                    }
                }

                .upload-tool {
                    display: flex;
                    margin-top: 12px;
                    align-items: center;
                    justify-content: space-between;

                    .label {
                        width: 120px;
                        display: flex;
                        color: #fff;
                        padding: 12px;
                        font-size: 12px;
                        cursor: pointer;
                        border-radius: 4px;
                        align-items: center;
                        box-sizing: border-box;
                        justify-content: center;
                        background-color: #409eff;
                    }

                    .input {
                        padding-left: 8px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .ocr-result {
            flex: 1;
            padding: 12px;
            display: flex;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            flex-direction: column;
            background-color: #f2f2f2;

            .ocr-box-title {
                width: 108px;
                height: 30px;
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 30px;
                text-align: center;
                background-color: #fff;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .ocr-result-box {
                flex: 1;
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                background-color: #fff;

                .ocr-result-loading {
                    height: 100%;
                    display: flex;
                    font-size: 14px;
                    color: #333333;
                    align-items: center;
                    justify-content: center;
                }
            }

        }
    }
}

@media (max-width:576px) {
    .face {
        width: 100%;

        .desc {
            margin: 0;
            padding: 12px;
            font-size: 12px;
            line-height: 24px;
        }

        .content {
            padding: 0 14px;
            box-sizing: border-box;
            flex-direction: column;

            .face-container {
                width: 100%;
                margin-right: 0;
                display: flex;
                flex-direction: column;

                .face-content {
                    flex: 1;
                    height: auto;

                    .ocr-box-title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .face-images-container {
                        padding: 6px;
                        background-color: #ffffff;

                        .example-images {
                            width: 50%;

                            .img-cell {
                                padding: 6px;
                                margin-bottom: 0;

                                img {
                                    width: 100%;
                                    height: 90px;
                                }
                            }
                        }

                        .result-img {
                            width: 50%;
                        }
                    }

                    .upload-tool {

                        .label {
                            width: 100%;
                        }

                        .input {
                            // display: none;
                        }
                    }
                }
            }

            .ocr-result {
                width: 100%;
                height: auto;

                .ocr-box-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>
