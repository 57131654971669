<template>
<div class="container">
    <!-- banner -->
    <div class="banner">
        <!-- 头部导航 -->
        <div class="head">
            <div class="nav">
                <img class="logo" :src="logo" alt="logo">
                <h3>{{title}}</h3>
            </div>
            <span class="desc">全自主研发，无第三方依赖，内外网自由部署</span>
        </div>
        <div class="desc-mobile">全自主研发，无第三方依赖，内外网自由部署</div>
        <div class="content">
            <div class="description">
                <h2>{{subTitle}}</h2>
                <p class="desc">{{desc}}</p>
            </div>
        </div>
        <div class="tabar">
            <div class="tab-cell" :class="{'active': idx === current}" v-for="(cell,idx) in tabar" :key="idx" @click="changeTab(idx,cell)">
                {{cell.name}}
            </div>
        </div>
        <div class="bg" :style="{'background-image':'url(' + bgCover + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}"></div>
    </div>
    <!-- 产品功能操作 -->
    <section id="experience" class="pro">
        <div class="module">
            <div class="title">
                <custom-title name="产品体验" />
            </div>
            <div class="module" v-if="current ===0">
                <txt-qrcode />
            </div>
            <div class="module" v-else-if="current === 1">
                <speech-compire />
            </div>
            <div class="module" v-else-if="current === 2">
                <img-compress />
            </div>
            <div class="module" v-else-if="current === 3">
                <face-compire />
            </div>
            <div class="module" v-else-if="current === 4">
                <img-classify />
            </div>
            <div class="module" v-else-if="current === 5">
                <sign />
            </div>
             <div class="module" v-else-if="current === 6">
                <ocr />
            </div>
        </div>
    </section>
    <section id="advantage" class="pro">
        <div class="module">
            <div class="title">
                <custom-title name="产品优势" />
            </div>
            <div class="product">
                <product :list="productList" />
            </div>
        </div>
    </section>
    <section id="scenario" class="pro">
        <div class="module">
            <div class="title">
                <custom-title name="应用场景" />
            </div>
            <scenario :list="example" />
        </div>
    </section>
    <customFooter />
</div>
</template>

<script>
import config from '@/lib/config';
import ocr from '@/components/ocr';
import sign from '@/components/sign';
import product from '@/components/product';
import scenario from '@/components/scenario';
import txtQrcode from '@/components/txt-qrcode';
import faceCompire from '@/components/face-compire';
import imgClassify from '@/components/img-classify';
import imgCompress from '@/components/img-compress';
import speechCompire from '@/components/speech-compire';
export default {
    components: {
        ocr,
        sign,
        product,
        scenario,
        txtQrcode,
        imgClassify,
        imgCompress,
        faceCompire,
        speechCompire,
    },
    data() {
        return {
            desc: '',
            current: 0,
            subTitle: '',
            activeName: '0',
            uploadFlag: false,
            tabar: config.navList,
            example: config.example2,
            bannerList: config.bannerList,
            productList: config.commonList,
            title: '人工智能公测V1.5',
            logo: '/images/common/logo.png',
            bgCover: '/images/common/banner5.jpg',
        }
    },
    mounted() {
        this.desc = this.bannerList[0].desc;
        this.subTitle = this.bannerList[0].name;
    },
    methods: {
        // 导航栏切换
        changeTab(idx, item) {
            this.current = idx;
            this.desc = this.bannerList[idx].desc;
            this.bgCover = this.bannerList[idx].bg;
            this.subTitle = this.bannerList[idx].name;
            if (!item.state) return this.$message.warning({
                offset: 80,
                message: '敬请期待',
            });
            this.example = item.name === '图像压缩' ? config.example1 : config.example2;
            this.productList = item.name === '图像压缩' ? config.imgCompressList : config.commonList;
        },
    }
}
</script> 

<style lang="scss" scoped>
.container {
    width: 100%;

    .banner {
        width: 100%;
        height: 500px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;

        .head {
            width: 100%;
            margin: 0 auto;
            max-width: 1200px;
            
            .nav {
                display: flex;
                align-items: center;

                .logo {
                    width: 49px;
                    height: 46px;
                    margin-right: 6px;
                }

                h3 {
                    color: #fff;
                    font-size: 28px;
                }
            }

            .desc {
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .desc-mobile {
            display: none;
        }

        .content {
            width: 100%;
            margin: 0 auto;
            max-width: 1200px;

            .description {
                width: 100%;
                color: #fff;
                display: flex;
                max-width: 60%;
                font-size: 14px;
                flex-direction: column;
                align-items: flex-start;

                h2 {
                    margin: 0;
                    font-size: 30px;
                }
            }
        }

        .bg {
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            background-color: #0b1455;
        }

        .tabar {
            width: 100%;
            display: flex;
            margin: 0 auto;
            max-width: 1200px;
            align-items: center;
            justify-content: space-between;

            .tab-cell {
                flex: 1;
                height: 88px;
                font-size: 20px;
                cursor: pointer;
                color: #9095af;
                line-height: 88px;
                font-weight: bold;
                text-align: center;
                letter-spacing: 2px;
                font-stretch: normal;
                background: #102367;
                transition: all linear .4s;
            }

            .active {
                color: #fff;
                background: #409eff;
            }
        }
    }

    .pro {
        width: 100%;

        .module {
            margin: auto;
            max-width: 1200px;

            .title {
                padding: 24px 0;
            }
        }
    }

    #advantage {
        max-width: none;
        margin-top: 24px;
        background: #f2f2f2;
    }

    #scenario {
        width: 100%;
        background: #ffffff;
    }
}

// 响应式布局
@media (max-width: 576px) {
    .container {
        width: 100%;

        .banner {
            width: 100%;
            height: 300px;
            position: relative;

            .head {
                top: 0;
                width: 100%;
                display: flex;
                margin-top: 0;
                padding: 12px;
                z-index: 99999;
                position: sticky;
                align-items: center;
                background: #ffffff;
                box-sizing: border-box;

                .nav {
                    display: flex;

                    .logo {
                        width: 32px;
                        height: 32px;
                    }

                    h3 {
                        margin: 0;
                        color: #333;
                        font-size: 18px;
                    }
                }

                .desc {
                    display: none;
                }
            }

            .desc-mobile {
                top: 80px;
                color: #fff;
                font-size: 12px;
                padding: 0 16px;
                display: block;
                position: absolute;
                box-sizing: border-box;
            }

            .content {
                width: 100%;
                margin: auto;
                padding: 0 16px;
                box-sizing: border-box;

                .description {
                    color: #fff;
                    display: flex;
                    font-size: 12px;
                    max-width: 100%;
                    flex-direction: column;
                    align-items: flex-start;

                    h2 {
                        margin: 0;
                        font-size: 20px;
                    }
                }
            }

            .tabar {
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                overflow-x: auto;
                position: absolute;
                align-items: center;
                overflow-y: hidden;
                white-space: nowrap;
                justify-content: space-between;

                .tab-cell {
                    flex: 1;
                    height: 40px;
                    display: flex;
                    padding: 0 8px;
                    font-size: 10px;
                    cursor: pointer;
                    color: #9095af;
                    letter-spacing: 1px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: center;
                    transition: all linear .4s;
                }

                .active {
                    color: #fff;
                    background: #409eff;
                }
            }

            .bg {
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                position: absolute;
                background-position: -500px 30px;
            }
        }

        .pro {
            width: 100%;

            .module {
                width: 100%;
                max-width: 100%;
            }
        }

        #advantage {
            margin-top: 12px;
            padding: 0px 0 12px 0;
        }

        #scenario {
            margin-top: 0px;
            padding: 0px 0 20px 0;
        }
    }
}
</style>
