<template>
<div class="special">
    <div class="cell" v-for="item in list" :key="item.id">
        <el-image class="cover" :src="item.icon" fit="fill "></el-image>
        <p class="title">{{item.title}}</p>
        <p class="desc">{{item.desc}}</p>
    </div>
</div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.special {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .cell {
        width: 32%;
        color: red;
        display: flex;
        height: 340px;
        padding: 20px 24px;
        margin:0 0 24px 0;
        border-radius: 8px;
        align-items: center;
        box-sizing: border-box;
        flex-direction: column;
        background-color: #ffffff;
        justify-content: space-around;

        .cover {
            width: 70px;
            height: 70px;
        }

        .title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
        }

        .desc {
            flex: 1;
            font-size: 14px;
            overflow: hidden;
            color: #333333;
        }
    }
}

@media (max-width: 576px) {
    .special {
        width: 100%;
        padding: 0 14px;
        box-sizing: border-box;

        .cell {
            width: 100%;
            color: red;
            height: auto;
            display: flex;
            padding: 16px 24px;
            border-radius: 4px;
            align-items: center;
            box-sizing: border-box;
            margin: 0px 0 12px 0;
            flex-direction: column;
            background-color: #ffffff;
            justify-content: space-around;

            .cover {
                width: 41px;
                height: 41px;
            }

            .title {
                margin: 6px 0;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
            }

            .desc {
                font-size: 12px;
                overflow: hidden;
                color: #333333;
            }
        }
    }
}
</style>
