<template>
<div class="content">
    <div class="cover">
        <img src="/images/common/i7.png" alt="示例封面">
    </div>
    <div class="list">
        <div class="cell" v-for="item in list" :key="item.id">
            <h6 class="title">{{item.title}}</h6>
            <p class="desc">{{item.desc}}</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    .cover {
        width: 510px;
        height: 340px;
        display: flex;
        align-items: center;
        background: #f3f9ff;
        justify-content: center;

        img {
            width: 90%;
            object-fit: cover;
        }
    }

    .list {
        flex: 1;
        margin-left: 32px;

        .cell {
            display: flex;
            margin-bottom: 16px;
            flex-direction: column;
            align-items: flex-start;

            .title {
                margin: 0;
                padding: 0;
                font-size: 18px;
                position: relative;
            }

            .title::before {
                top: 0;
                width: 0;
                height: 0;
                bottom: 0;
                content: '';
                left: -16px;
                margin: auto;
                position: absolute;
                border-left: 8px solid #409eff;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }

            .desc {
                color: #333;
                margin: 6px 0;
                font-size: 14px;
            }
        }
    }
}

@media (max-width:576px) {
    .content {
        margin-top: 0px;
        align-items: center;
        flex-direction: column;

        .cover {
            width: 100%;
            height: 280px;
            display: flex;
            align-items: center;
            background: #f3f9ff;
            justify-content: center;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .list {
            flex: 1;
            margin-top: 24px;

            .cell {
                display: flex;
                margin-bottom: 16px;
                flex-direction: column;
                align-items: flex-start;

                .title {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    position: relative;
                }

                .title::before {
                    top: 0;
                    width: 0;
                    height: 0;
                    bottom: 0;
                    content: '';
                    left: -16px;
                    margin: auto;
                    position: absolute;
                    border-left: 8px solid #409eff;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                }

                .desc {
                    color: #333;
                    margin: 6px 0;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
