<template>
<div class="proModule">
    <p class="desc">采用深度学习卷积网络为编码内核，提供对图片、视频压缩服务。支持对JPG、PNG、GIF、TIFF等格式压缩。</p>
    <div class="handle-container">
        <div class="scroll">
            <div class="cell" :class="{'active': idx===active}" v-for="(cell,idx) in scrollList" :key="idx" @click="change(idx,cell)">
                <img class="img" :src="cell.src" alt="示例图">
                <div :style="{position:'absolute',background:'#6c6d72',opacity:'0.8',width:'40px',height:'36px',textAlign:'center',lineHeight:'40px',bottom:'0',right:'0',color:'#fff',fontSize:'12px',borderRadius:'100px 0 0 0 ',textIndent:'10px'}">
                    {{cell.type}}
                </div>
            </div>
        </div>
        <div class="source" v-loading="loading" element-loading-text="文件压缩中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="expmale">
                <el-image style="width:300px;height:200px" fit="contain" :src="source" :preview-src-list="[source]">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </div>
            <div class="tool">
                <el-upload class="upload-demo" :http-request="uploadFile" action="" accept="image/png,image/jpeg,image/gif,image/tiff" :show-file-list="false">
                    <el-button type="primary">本地上传</el-button>
                </el-upload>
                <el-input v-model="searchVal" placeholder="请输入或者粘贴图像链接URL" @input="searchImg"></el-input>
            </div>
        </div>
        <div class="target">
            <div class="targetSelect">
                <el-select v-model="selectValue" @change="onChange(selectValue)" :disabled="loading?true:false">
                    <el-option value="低品质">低品质</el-option>
                    <el-option value="中品质">中品质</el-option>
                    <el-option value="高品质">高品质</el-option>
                </el-select>
            </div>
            <div class="result">
                <div class="item1">
                    <el-image v-if="!carousel.length" style="width:300px;height:200px" fit="scale-down" :src="compress.Decompress_Image" :preview-src-list="[compress.Decompress_Image]">
                        <template #error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                    <!-- tiff解压轮播展示 -->
                    <el-carousel v-else height="200px">
                        <el-carousel-item v-for="(img,idx) in carousel" :key="idx">
                            <el-image style="width:300px;height:200px" fit="scale-down" :src="baseUrl+img" :preview-src-list="[baseUrl+img]">
                                <template #error>
                                    <div class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </template>
                            </el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="item2">
                    <div class="list">
                        <div class="cell">
                            <h4 class="title">1、原文件参数</h4>
                            <p>文件类型：{{compress.file_type ? compress.file_type:'待处理'}}</p>
                            <p>图片尺寸：{{compress.Orignal_Image_Weight ? compress.Orignal_Image_Weight + '*'+ compress.Orignal_Image_Height:'待处理'}}</p>
                            <p>图片大小：{{compress.Original_Image_Size ? compress.Original_Image_Size +'KB':'待处理'}}</p>
                        </div>
                        <div class="cell">
                            <h4 class="title">2、处理后文件参数</h4>
                            <p style="font-weight:bold">压缩比例：{{compress.Compress_Ratio ? compress.Compress_Ratio +'倍' : '待处理'}}</p>
                            <p>图片尺寸：{{compress.Decompress_Image_Weight ? compress.Decompress_Image_Weight + '*' + compress.Decompress_Image_Height:'待处理'}}</p>
                            <p>压缩文件大小：{{compress.Compress_Image_Size ? compress.Compress_Image_Size+'KB' : '待处理'}}</p>
                            <p>PSNR（峰值信噪比）：{{compress.PSNR || '待处理'}}</p>
                            <p>MS-SSIM（多层结构相似性）：{{compress.MS_SSIM || '待处理'}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tool">
                <div class="item">已压缩图片预览</div>
                <div class="handle" @click="downFile()">
                    <el-button type="primary">下载压缩包</el-button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        this.searchImg = this.debounce(() => {
            this.search();
        }, 1000);
        return {
            quality: '_0',
            file: null,
            active: null,
            loading: false,
            // identifyImg:'',
            carousel: [],
            source: '',
            target: '',
            compress: {},
            searchVal: '',
            selectValue: '低品质',
            scrollList: [{
                    id: 0,
                    src: '/images/compress/1.jpg',
                    type: 'jpg'
                },
                {
                    id: 1,
                    src: '/images/compress/2.png',
                    type: 'png'
                },
                {
                    id: 2,
                    src: '/images/compress/3.bmp',
                    type: 'bmp'
                },
                {
                    id: 3,
                    src: '/images/compress/4.jpeg',
                    type: 'jpeg'
                }
            ]
        }
    },
    methods: {
        change(idx, item) {
            if (this.loading) return this.$message.warning('当前图片压缩中,请稍等')
            this.active = idx;
            this.onlineHandle(item.src)
        },
        // 设置文件名以便修改查看品质
        setFileName(file) {
            let name = file.name;
            if (file.type == 'image/jpeg') name = name.substring(0, name.length - 5) + this.quality + name.substring(name.length - 5, name.length)
            else name = name.substring(0, name.length - 4) + this.quality + name.substring(name.length - 4, name.length);
            return name
        },
        // 图片上传
        async uploadFile(files) {
            this.loading = true;
            let file = files.file || files;
            if (file.size >= 10 * 1024 * 1024) return this.$message.warning('图片大小请控制在10兆以内');
            this.fileToBlob(file, fn => {
                this.source = fn;
            });
            let formdata = new FormData();
            formdata.append('file', file, this.setFileName(file));
            this.file = file;
            const res = await this.$upLoadFile('/ai-img/detect', formdata).catch(err => {
                this.loading = false;
            });
            if (res.success && res.data) {
                let data = res.data;
                this.searchVal = '';
                this.loading = false;
                if (data.Original_Image instanceof Array) {
                    this.carousel = data.Original_Image;
                }
                data.PSNR = parseFloat(data.PSNR).toFixed(2);
                data.MS_SSIM = parseFloat(data.MS_SSIM).toFixed(2);
                data.Compress_Ratio = parseFloat(data.Compress_Ratio).toFixed(2);
                data.Compress_Image_Size = parseFloat(data.Compress_Image_Size).toFixed(2);
                data.Original_Image_Size = parseFloat(data.Original_Image_Size).toFixed(2);
                this.compress = res.data;
                console.log(this.compress)
                this.$message.success('处理成功');
            } else {
                this.loading = false;
                this.$message.error(res.message);
            }
        },
        async search() {
            if (this.searchVal == '') return;
            const res = await this.$httpPost('/ai-web/home/dowImg', {
                url: this.searchVal,
            })
            if (res.success && res.data) {
                let base = res.data;
                let file = this.base64toFile(base, '图片');
                this.uploadFile(file);
                return;
            }
            this.$message('图片下载失败');
        },
        // 在线图片压缩
        // downloadImg() {
        //     this.onlineHandle(this.searchVal);
        // },
        // 处理在线、本地相对路径图片链接
        onlineHandle(url) {
            this.imgToBase64(url, base => {
                let file = this.base64toFile(base, '图片');
                return this.uploadFile(file)
            })
        },
        // 下载压缩文件
        downFile() {
            if (!Object.keys(this.compress).length) return this.$message.warning(this.source == '' ? '请上传图片' : '图片待处理中')
            let file = this.base64toFile('data:application/hfc;base64,' + this.compress.Compress_Image, 0);
            let aTag = document.createElement('a');
            aTag.download = file.name;
            let href = URL.createObjectURL(file);
            aTag.href = href;
            aTag.click();
            URL.revokeObjectURL(href);
        },
        // 切换品质
        onChange(val) {
            val == '高品质' ? (this.quality = "_2") : val == '中品质' ? (this.quality = "_1") : this.quality = "_0"
            if (this.source == '') return;
            if (this.file) return this.uploadFile(this.file);
            if (this.searchVal || this.source) return this.onlineHandle(this.searchVal || this.source);
        }
    }
}
</script>

<style lang="scss" scoped>
.proModule {
    width: 100%;

    .desc {
        font-size: 14px;
        color: #333333;
        text-align: center;
        margin: 18px 0 28px 0;
    }

    .handle-container {
        width: 100%;
        display: flex;
        background: #fff;

        .scroll {
            width: 110px;
            display: flex;
            padding: 10px;
            border-radius: 4px;
            background: #f2f2f2;
            flex-direction: column;
            justify-content: flex-start;

            .cell {
                display: flex;
                cursor: pointer;
                padding: 6px 6px;
                background: #fff;
                border-radius: 4px;
                margin-bottom: 12px;
                align-items: center;
                position: relative;
                justify-content: center;
                transition: all linear .3s;
                border: 2px solid rgba(0, 0, 0, 0);

                .img {
                    width: 99px;
                    height: 62px;
                    object-fit: cover;
                }
            }

            .cell:last-child {
                margin-bottom: 0;
            }

            .active {
                border: 2px solid #409eff;
            }
        }

        .source,
        .target {
            width: 452px;
            display: flex;
            padding: 10px;
            overflow: hidden;
            margin-left: 12px;
            border-radius: 4px;
            background: #f2f2f2;
            flex-direction: column;
            justify-content: space-between;

            .expmale,
            .result {
                flex: 1;
                display: flex;
                padding: 40px 0;
                margin-top: 32px;
                background: #fff;
                position: relative;
                align-items: center;
                justify-content: center;

                .el-image {
                    .image-slot {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            font-size: 42px;
                        }
                    }
                }
            }

            .expmale::before {
                left: 0;
                top: -32px;
                width: 108px;
                height: 32px;
                font-size: 16px;
                font-weight: 600;
                line-height: 32px;
                text-align: center;
                content: '压缩内容';
                position: absolute;
                background: #ffffff;
            }

            .expmale::after {
                left: 120px;
                top: -32px;
                font-size: 12px;
                line-height: 32px;
                color: #fa0202;
                position: absolute;
                content: '*温馨提示：图片大小请控制在10兆以内*';
            }

            .result::before {
                left: 0;
                top: -32px;
                width: 108px;
                height: 32px;
                font-size: 16px;
                font-weight: 600;
                line-height: 32px;
                text-align: center;
                content: '处理结果';
                position: absolute;
                background: #ffffff;
            }

            .result {
                width: 100%;

                .item1 {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px dashed #e4e5e7;

                    .el-carousel {
                        width: 100%;

                        .el-carousel__item {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }

                .item2 {
                    font-size: 14px;
                    padding: 0 16px;
                    box-sizing: border-box;

                    .list {
                        width: 100%;

                        .cell {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: flex-start;

                            p {
                                width: 100%;
                                margin: 4px 0;
                                font-size: 10px;
                                box-sizing: border-box;
                            }

                            .title {
                                width: 100%;
                                margin-top: 0;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .tool {
                display: flex;
                margin-top: 12px;
                align-items: center;
                justify-content: space-between;

                .el-input {
                    margin-left: 12px;
                }

                .item {
                    flex: 1;
                    padding: 12px 0;
                    font-size: 14px;
                    text-align: center;
                    background: #f5f7fa;
                }

                .handle {
                    width: 200px;
                    margin-left: 12px;

                    .el-button {
                        width: 100%;
                    }
                }
            }
        }

        .target {
            flex: 1;
            position: relative;

            .targetSelect {
                top: 10px;
                left: 118px;
                width: 108px;
                height: 32px;
                font-size: 14px;
                background: #fff;
                line-height: 32px;
                text-align: center;
                position: absolute;
                border-left: solid 1px #e4e5e7;
            }

        }
    }
}

.targetSelect {

    /deep/ .el-select,
    /deep/ .select-trigger,
    /deep/ .el-input,
    /deep/ .el-input__icon {
        height: 32px;
        line-height: 32px;
    }

    /deep/ .el-input__icon {
        color: #409eff
    }

    /deep/ .el-input__inner {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: inline-block;
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: var(--el-border-transition-base);
        transition: var(--el-border-transition-base);
        width: 100%;
        color: #409eff
    }
}

@media (max-width:576px) {
    .proModule {
        width: 100%;

        .desc {
            margin: 12px 0;
            font-size: 12px;
        }

        .handle-container {
            width: 100%;
            padding: 0 14px;
            box-sizing: border-box;
            flex-direction: column;

            .scroll {
                width: 100%;
                display: flex;
                padding: 6px 12px;
                flex-direction: row;
                box-sizing: border-box;
                justify-content: space-between;

                .cell {
                    margin-bottom: 0;

                    .img {
                        width: 90px;
                        height: 62px;
                    }
                }
            }

            .source,
            .target {
                width: 100%;
                margin-left: 0;
                margin-top: 12px;
                padding: 10px 12px;
                box-sizing: border-box;

                .expmale,
                .result {
                    width: 100%;
                    padding: 20px 0;

                    .el-image {
                        .image-slot {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            i {
                                font-size: 42px;
                            }
                        }
                    }
                }

                .expmale::before {
                    display: flex;
                    font-weight: bold;
                    align-items: center;
                    justify-content: center;
                }

                .result::before {
                    display: flex;
                    font-weight: bold;
                    align-items: center;
                    justify-content: center;
                }

                .result {
                    flex-direction: column;

                    .item1 {
                        flex: 2;
                        border-right: none;

                        .el-carousel {
                            width: 100%;

                            .el-carousel__item {
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }

                    .item2 {
                        flex: 1;
                        width: 100%;
                        padding: 0 12px;

                        .list {
                            height: auto;
                            margin-top: 12px;

                            .cell {
                                margin-top: 12px;

                                p {
                                    margin: 6px 0;
                                }

                                .title {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .tool {
                    .handle {
                        flex: 1;
                    }
                }
            }
        }
    }

    .targetSelect {
        /deep/ .el-input__inner {
            font-size: 14px;
        }
    }
}
</style>
