export default {
    // 阿里云文字识别地址
    aliOCRip: 'https://ai.aliyun.com/ocr?spm=5176.12127985.J_8058803260.51.68482cc7bB67yZ',
    //阿里云语音识别地址
    aliSpeechip: 'https://ai.aliyun.com/nls/filetrans?spm=5176.12061031.J_6765749500.1.279a6822uGgFg1',
    // 轮播参数配置
    bannerList: [{
        id: 0,
        state: true,
        name: "OCR文字识别",
        bg: '/images/common/banner5.jpg',
        desc: "基于深度学习的OCR文字识别，主要通过分类、PSENet、CRNN及版面分析等算法，快速且精准识别出各项属性信息内容。",
    },
    {
        id: 1,
        state: true,
        name: "音视频识别",
        bg: "/images/common/banner2.jpg",
        desc: "基于深度学习的语音识别，采用领先算法，可将音频或视频内的音频文件，快速转换成文字。"
    },
    {
        id: 2,
        state: true,
        name: "图像压缩",
        bg: "/images/common/banner.jpg",
        desc: "基于深度学习的压缩算法，高效压缩图片大小，仍保持原图视觉质量，做到不失真无损压缩，可对多种格式压缩，支持一键批量压缩图像。缩小约为原图像的21倍"
    },
    {
        id: 3,
        state: true,
        name: "人脸对比",
        bg: "/images/common/banner3.jpg",
        desc: "基于深度学习的压缩算法，比对图片中两张人脸的相似度，并反馈相似度分值。。"
    },
    // 图像分类
    {
        id: 4,
        state: true,
        name: "证件识别",
        bg: '/images/common/banner4.jpg',
        desc: "通过AI算法模型检测识别输入图像，上传图像经计算机识别分析，自动得出目标信息，从而判断出证件类型。"
    },
    {
        id: 5,
        state: true,
        name: "签字签章检测",
        bg: "/images/common/banner2.jpg",
        desc: "采用目标检测方法，检测识别合同、申请等资料文件中是否包含签字或签章。"
    },
    {
        id: 6,
        state: false,
        name: "活体检测",
        bg: "/images/common/banner2.jpg",
        desc: "基于深度学习的活体检测，计算机通过随机头部运动，快速识别判断检测对象为真人。"
    }],
    // 导航参数配置
    navList: [{
        id: 0,
        state: true,
        name: 'OCR文字识别'
    },
    {
        id: 1,
        state: true,
        name: '音视频识别'
    },
    {
        id: 2,
        state: true,
        name: '图像压缩'
    },
    {
        id: 3,
        state: true,
        name: '人脸对比'
    },
    {
        id: 4,
        state: true,
        name: '证件识别'
    },
    {
        id: 5,
        state: true,
        name: '签字签章检测'
    },
    {
        id: 6,
        state: true,
        name: '活体检测'
    }
    ],
    // 图像压缩产品说明
    imgCompressList: [
        {
            id: 0,
            title: '全自主研发',
            icon: '/images/common/i15.png',
            desc: "专项研发团队，基于人工智能技术，专项研发团队进行样本收集、目标标注、模型训练、模型主要优化、场景优化、模型评估等多个模块，通过人工智能算法模型，实现垂直于不动产登记、房屋交易等领域内精准且快速的图像自动检测识别。"
        },
        {
            id: 1,
            title: '无第三方依赖',
            icon: '/images/common/i16.png',
            desc: "全流程专项团队研发，不依赖任何第三方服务，能及时根据场景需求，进行个性化定制开发。"
        },
        {
            id: 2,
            title: '内外网自由部署',
            icon: '/images/common/i17.png',
            desc: "所有数据经过加密封装，可以根据实际需要，支持内外网自由部署。"
        },
        {
            id: 3,
            title: '压缩效率高',
            icon: '/images/common/i4.png',
            desc: '目前网站、社交媒体上的高清图片日益增多，随之而来的带宽消耗也不断增大，该产品可帮助节省55%带宽成本。',
        },
        {
            id: 4,
            title: '主观效果好',
            icon: '/images/common/i3.png',
            desc: '在同等压缩比例下，该产品的ssim值优于其他图片压缩。',
        },
        {
            id: 5,
            title: '占用内存小',
            icon: '/images/common/i5.png',
            desc: '随着相机质量越来越高，服务器存储空间越来越不够用，该产品可使有限的存储空间容纳更多的图片和视频。',
        }],
    // 其它产品说明
    commonList: [
        {
            id: 0,
            title: '全自主研发',
            icon: '/images/common/i15.png',
            desc: "专项研发团队，基于人工智能技术，专项研发团队进行样本收集、目标标注、模型训练、模型主要优化、场景优化、模型评估等多个模块，通过人工智能算法模型，实现垂直于不动产登记、房屋交易等领域内精准且快速的图像自动检测识别。"
        },
        {
            id: 1,
            title: '无第三方依赖',
            icon: '/images/common/i16.png',
            desc: "全流程专项团队研发，不依赖任何第三方服务，能及时根据场景需求，进行个性化定制开发。"
        },
        {
            id: 2,
            title: '内外网自由部署',
            icon: '/images/common/i17.png',
            desc: "所有数据经过加密封装，可以根据实际需要，支持内外网自由部署。"
        },
        {
            id: 3,
            title: '多元化',
            icon: '/images/common/i11.png',
            desc: "满足不同业务使用场景，识别图像核心内容，可快速准确提取所需信息，可应用于政务服务、银行、汽车、教育、医疗、商务、电信等。可识别身份证、结婚证、户口簿、不动产权证、合同、营业执照等敏感资料，即保障内容高效识别又可保障数据安全。"
        },
        {
            id: 4,
            title: '准确性',
            icon: '/images/common/i14.png',
            desc: "通过海量图片样本，学习训练模型，准确率超95%以上。在不动产登记与交易行业内具有前列的准确率。"
        },
        {
            id: 5,
            title: '便捷性',
            icon: '/images/common/i10.png',
            desc: "支持本地物理机、本地虚拟机、云主机服务器等多种部署方式，提供一键部署工具和常用运维工具。"
        },
        {
            id: 6,
            title: '稳定性',
            icon: '/images/common/i13.png',
            desc: "扩展性优良，算法持续迭代优化，不断更新服务且对用户的稳定性无影响。"
        },
        {
            id: 7,
            title: '个性化',
            icon: '/images/common/i12.png',
            desc: "根据场景需求，个性化定制开发，并针对特殊情况进行专项训练优化。"
        },
        {
            id: 8,
            title: '安全性',
            icon: '/images/common/i9.png',
            desc: "离线部署，保护数据私密性和安全性。专有网络环境下本地化部署，数据无需上传至第三方服务器或云端，保障企业核心生产数据的私密性和安全性"
        }
    ],
    // 文字识别导航切换描述
    txtQrcodeList: [
        {
            id: 0,
            name: "身份证正反面",
            desc: "识别不动产权证核心字段信息。识别字段：不动产权证号、权利人、坐落、不动产单元号。（可根据需求，增加字段检测识别）"
        },
        {
            id: 1,
            name: "不动产权属证书",
            desc: "识别不动产权证核心字段信息。识别字段：不动产权证号、权利人、坐落、不动产单元号。（可根据需求，增加字段检测识别）"
        },
        {
            id: 2,
            name: "户口本户主页",
            desc: "识别户口簿户主页核心字段信息。识别字段：户别、户主姓名、住址。"
        },
        {
            id: 3,
            name: "常住人口页",
            desc: "识别户口簿常住人口页核心字段信息。识别字段：姓名、户主或户主关系、曾用名、性别、出生地、民族、籍贯、出生日期、公民身份证件编号。"
        }
    ],
    // 应用场景1
    example1: [{
        id: 0,
        title: '高清地图、无人车',
        desc: '在线地图对图片的消耗是非常巨大的，该产品在保持相同客观感受的情况下，相比JPEG可以节省更多码字。'
    },
    {
        id: 1,
        title: '视频存储（公安、交通、商城）',
        desc: '安防监控产生的视频图片数量巨大，该产品在保证视频清晰度的同时，能显著降低视频存储成本。'
    },
    {
        id: 2,
        title: '新闻、电商、旅游',
        desc: '图片带宽消耗占新闻、电商、旅游客户端流量消耗的大头，在保证一定清晰度的同时尽可能降低图片的数据量是用户的需求。该产品在低码字的情况下，其主观效果明显优于其他图片压缩格式。'
    },
    {
        id: 3,
        title: '手机、汽车、无人机、电视',
        desc: '随着相机的质量越来越高，手机、相机、电视机顶盒的存储空间越来越不够用。该产品可使有限的存储空间容纳更多的图片和视频。'
    }],
    // 应用场景2
    example2: [
        {
            id: 0,
            title: "政务服务",
            desc: "不动产登记与交易、税务、民政、公安、工商等。具体应用场景如窗口收件、在线预审、档案数字化加工、农房确权登记、户口迁移、新生儿建档、子女入学、婚姻登记等。",
        },
        {
            id: 1,
            title: "金融",
            desc: "银行开户、抵押贷款、远程开户、保险回执等。",
        },
        {
            id: 2,
            title: "医疗",
            desc: "数据分析、费用报销、票据电子化等。"
        },
        {
            id: 3,
            title: "教育",
            desc: "出勤考核、论文管理、教学内容处理、作业批改等。",
        },
        {
            id: 4,
            title: "汽车",
            desc: "信息登记、车辆保险、运输平台身份信息认证、小区门禁等。",
        }
    ],
    // 文字识别描述
    txtDesc: '识别二代居民身份证正面所有字段信息。识别字段：姓名、性别、民族、出生日期、住址、公民身份号码、签发机关、有效期限。',
    // 图像分类描述
    imgClassifyDesc: '识别图像中的内容，判断图片中是否有相应证件/资料信息，从而区分证件类型。',
    // 人脸对比描述
    faceDesc: '提取人脸区域，计算出两个人脸特征，直接在欧式空间里的距离的度量两个人脸相似度，大于给定阈值分数值为同一个人，否则不是。',
    // 签字签章描述
    sign: '支持圆形、椭圆形、方形等印章。'
}