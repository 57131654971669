<template>
<div class="qrcode hideToShow">
    <!-- 工具栏 -->
    <div class="tool">
        <ul class="list">
            <li class="cell" :class="{'active' :num==idx}" v-for="(item,idx) in tabList" :key="item.id" @click="changeItem(item,idx)">
                {{item.name}}
            </li>
        </ul>
    </div>
    <!-- 描述信息 -->
    <p class="desc">{{ desc }}</p>
    <!-- 内容区 -->
    <div class="content">
        <div class="example">
            <div class="cell" :class="{'isActive' :current == idx}" v-for="(item,idx) in examplelist" :key="item.id" @click="changeImg(item,idx)">
                <img class="img" :src="item" alt="示例图片" />
            </div>
        </div>
        <!-- 识别内容 -->
        <div class="ocr-content" v-loading="loading" element-loading-text="识别中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="ocr-box-title">识别内容</div>
            <div class="images-container">
                <el-image style="width:95%;height:95%;border-radius:4px;" fit="contain" :src="identifyImg" :preview-src-list="[identifyImg]">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </div>
            <div class="upload-tool">
                <el-upload class="upload-demo" :http-request="fileChange" action="" accept="image/*" :show-file-list="false">
                    <el-button type="primary">本地上传</el-button>
                </el-upload>
                <el-input class="input" v-model="inputImg" placeholder="请输入或者粘贴图像链接URL" @input="searchImg" />
            </div>
        </div>
        <!-- 识别结果 -->
        <div class="ocr-result">
            <div class="ocr-box-title">处理结果</div>
            <div class="ocr-result-box">
                <!-- 文字检测类型 -->
                <el-table v-if="ocrList.length" max-height="450" :data="ocrList" border style="width: 100%">
                    <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                    <el-table-column prop="text" label="识别文字" align="center"></el-table-column>
                    <el-table-column prop="result" label="识别结果" align="center"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import config from '@/lib/config.js';
export default {
    data() {
        this.searchImg = this.debounce(() => {
            this.search();
        }, 1000);
        return {
            num: 0,
            type: '',
            ocrList: [],
            current: 0,
            inputImg: '',
            fileList: [],
            loading: false,
            desc: config.txtDesc,
            txtQrcodeList: config.txtQrcodeList,
            identifyImg: "/images/idcard/5.png",
            examplelist: [
                '/images/idcard/5.png',
                '/images/idcard/4.jpg'
            ],
            tabList: [{
                    id: 0,
                    name: "身份证正反面",
                },
                {
                    id: 1,
                    name: "不动产权属证书",
                },
                {
                    id: 2,
                    name: "户口本户主页",
                },
                {
                    id: 3,
                    name: "常住人口页",
                }
            ],
        }
    },
    mounted() {
        this.getImageBase64(this.identifyImg, async (base64Img) => {
            this.identifyImg = base64Img;
            await this.onSubmit();
        })
    },
    methods: {
        // 同行对比
        jump() {
            window.open(config.aliOCRip);
        },
        changeImg(img, idx) {
            this.inputImg = '';
            idx != 5 ? this.current = idx : ''
            this.getImageBase64(img, async (base64Img) => {
                this.identifyImg = base64Img;
                await this.onSubmit();
            })
        },
        // 本地文件上传
        fileChange(e) {
            this.inputImg = '';
            const fileData = e.file;
            const reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = (s) => {
                this.identifyImg = s.target.result;
                this.onSubmit();
            };
        },
        async search() {
            if (this.inputImg == '') return;
            const res = await this.$httpPost('/ai-web/home/dowImg', {
                url: this.inputImg,
            })
            if (res.success && res.data) {
                this.identifyImg = res.data;
                this.onSubmit();
                return;
                s
            }
            this.$message('图片下载失败');
        },
        async onSubmit() {
            this.loading = true;
            const res = await this.$httpPost('/ai-web/OrcApi/RequestOcr', {
                imgString: this.identifyImg,
            }).catch(err => {
                this.loading = false
            })
            if (res.data.data && res.success) {
                let tmp = [];
                let data = res.data;
                this.type = data.data[0].type;
                let result = data.data[0].words.words_result;
                Object.keys(result).map(key => {
                    tmp.push({
                        'text': key,
                        'result': result[key]['words']
                    })
                });
                this.loading = false;
                this.ocrList = [...tmp];
                return this.$message.success({
                    offset: 80,
                    message: '处理成功',
                })
            }
            this.loading = false;
            this.$message.error(res.data.message);
        },

        /*  转换本地图片为base64 */
        getImageBase64(url, callback) {
            let canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d"),
                img = new Image;
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL('image/png');
                callback.call(this, dataURL);
                canvas = null;
            };
            img.src = url;
        },
        // 切换图片示例类型
        changeItem(item, idx) {
            this.num = idx;
            this.current = 0;
            this.identifyImg = '';
            this.desc = idx != 5 ? this.txtQrcodeList.find(cell => cell.name == item.name).desc : '';
            switch (idx) {
                case 0:
                    this.examplelist = [
                        "/images/idcard/5.png",
                        "/images/idcard/4.jpg",
                    ];
                    break;
                case 1:
                    this.examplelist = [
                        "/images/house/5.jpg",
                        "/images/house/3.jpg",
                    ];
                    break;
                case 2:
                    this.examplelist = [
                        "/images/populace/3.jpg",
                        // "/images/populace/2.png",
                    ];
                    break;
                case 3:
                    this.examplelist = [
                        "/images/residence/6.jpg",
                        // "/images/residence/2.png",
                        // "/images/residence/3.png",
                        // "/images/residence/4.png",
                    ];
                    break;
                    // case 5:
                    //     window.open('https://ai.aliyun.com/ocr?spm=5176.12127985.J_8058803260.51.68482cc7bB67yZ')
                    //     break;
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.msg-popup {
    width: 200px;
    border: 1px solid red;
}

.qrcode {
    width: 100%;

    .tool {
        width: 100%;
        height: 78px;
        background-color: #ffffff;

        .list {
            height: 100%;
            margin: auto;
            display: flex;
            max-width: 1200px;
            justify-content: space-between;

            .cell {
                height: 100%;
                display: flex;
                cursor: pointer;
                font-size: 14px;
                position: relative;
                align-items: center;
                justify-content: center;

            }

            .cell:hover {
                color: #409eff;
                transition: all linear .2s;
            }

            .active {
                color: #409eff;
                position: relative;
                transition: all linear .2s;
            }

            .active::after {
                left: 0;
                right: 0;
                content: '';
                bottom: 0;
                width: 160%;
                height: 2px;
                margin: auto;
                position: absolute;
                background: #409eff;
            }
        }
    }

    .desc {
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        margin-top: 24px;
        line-height: 24px;
        color: #333333;
        text-align: center;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .example {
            width: 150px;
            height: 450px;
            display: flex;
            overflow-y: auto;
            padding: 12px 10px;
            border-radius: 4px;
            flex-direction: column;
            box-sizing: border-box;
            background-color: #f2f2f2;

            .cell {
                width: 100%;
                padding: 6px;
                cursor: pointer;
                margin-bottom: 6px;
                border-radius: 4px;
                background: #fff;
                box-sizing: border-box;
                transition: all linear .3s;
                border: 2px solid rgba(0, 0, 0, 0);

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }

            .isActive {
                border: 2px solid #409eff;
            }
        }

        .ocr-content,
        .ocr-result {
            flex: 1;
            height: 450px;
            padding: 12px;
            display: flex;
            margin-left: 12px;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            flex-direction: column;
            background-color: #f2f2f2;
            justify-content: space-between;

            .ocr-box-title {
                width: 108px;
                height: 30px;
                display: flex;
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .images-container {
                flex: 1;
                width: 100%;
                display: flex;
                overflow: hidden;
                padding: 12px 34px;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                background-color: #fff;

                .el-image {
                    .image-slot {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            font-size: 42px;
                        }
                    }
                }
            }

            .ocr-result-box {
                flex: 1;
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                background-color: #fff;
            }

            .upload-tool {
                display: flex;
                margin-top: 12px;
                align-items: center;
                justify-content: space-between;

                .label {
                    width: 120px;
                    display: flex;
                    color: #fff;
                    padding: 12px;
                    font-size: 12px;
                    cursor: pointer;
                    border-radius: 4px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: center;
                    background-color: #409eff;
                }

                .input {
                    margin-left: 12px;
                    box-sizing: border-box;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .qrcode {
        width: 100%;

        .tool {
            width: 100%;
            height: 52px;
            background-color: #ffffff;

            .list {
                overflow: auto;
                padding: 0 14px;
                white-space: nowrap;
                box-sizing: border-box;
                justify-content: space-between;

                .cell {
                    font-size: 12px;
                    padding: 0 12px;
                }

                .active::after {
                    width: 80%;
                }
            }
        }

        .desc {
            font-size: 12px;
            margin-top: 12px;
            padding: 6px 14px;
            box-sizing: border-box;
        }

        .content {
            display: flex;
            padding: 0 14px;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;

            .example {
                width: 100%;
                height: auto;
                padding: 12px;
                flex-direction: row;
                box-sizing: border-box;

                .cell {
                    flex: 1;
                    height: 120px;
                    margin-right: 6px;
                    border-radius: 4px;

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }

                .cell:last-child {
                    margin-right: 0;
                }
            }

            .ocr-content,
            .ocr-result {
                width: 100%;
                height: auto;
                margin-left: 0;
                margin-top: 12px;

                .images-container {

                    .el-image {
                        .image-slot {
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
}
</style>
